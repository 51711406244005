<template>
  <div>
    <a-modal :visible.sync="show"
             width="960px"
             class="thirdparty-refund"
             title="申请退款"
             :footer="null"
             @cancel="$emit('update:show',false)">
      <div v-for="(itemorder, indexorder) in infoDataArr" :key="indexorder" class="footer" style="margin-top:0;">
        <a-form-model :model="itemorder.formData"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol"
                      ref="refundDetailRef">
          <div
          >
            <div class="flex justify-between mt-3 mb-2">
              <span
                class="text-sm font-bold text-black"
              >订单ID {{itemorder.order_no}}</span>
              <!-- <span>运费 {{ itemorder.freight_amount }}元</span> -->
            </div>
            <table class="tablegoodlist">
              <tr>
                <th style="width:60px;">
                  <!-- <a-checkbox @change="(e)=>onChangeAll(e, itemorder)">
                    
                  </a-checkbox> -->
                </th>
                <th>分类</th>
                <th>SKU</th>
                <th>商品名称</th>
                <th>口味</th>
                <th>尺寸</th>
                <th>订单价格</th>
              </tr>
              <tr v-for="(itemg, indexg) in itemorder.goods_list" :key="indexg">
                <td>
                  <a-checkbox @change="(e)=>onChangeItem(e, itemorder)" v-model="itemg.checked">
                  </a-checkbox>
                </td>
                <td>{{ formatGoodsType(itemg.goods_type) }}</td>
                <td>{{ itemg.goods_sku_number }}</td>
                <td>{{ itemg.goods_name }}</td>
                <td>{{ itemg.flavor_name }}</td>
                <td>{{ itemg.specification_name }}</td>
                <td>{{ itemg.order_price }} </td>
              </tr>
            </table>
          </div>

          <a-row>
            <a-col :span="24">
              <a-form-model-item prop :labelCol="{ span: 2 }" label="退款理由">
                <a-select placeholder="退款理由（顾客可见）" 
                    @change="handleChangeSelectReason"
                    v-model="itemorder.formData.refund_reason">
                      <a-select-option v-for="(item, index) in REASON_LIST"
                          :key="index"
                          :value="item">{{item}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row style="margin-bottom: 10px;">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" label="图片说明">
                <uploadImage type="24" multiple :value.sync="itemorder.imgList" @getList="(list)=>{itemorder.imgList = list}" />
              </a-form-model-item>
            </a-col>
          </a-row>
          
          <!-- 金额 待审核 -->
          <a-row>
            <!-- 只有一个订单 -->
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" prop label="退款金额">
                <a-input-number v-if="itemorder.order_type == 1" :min="0" :step="0.01" 
                  v-model="itemorder.formData.audit_pick_up_order_refund_amount" 
                  @change="(e)=>changeNumber(e, itemorder)"></a-input-number>
                <a-input-number v-if="itemorder.order_type == 2" :min="0" :step="0.01" 
                  v-model="itemorder.formData.audit_delivery_order_refund_amount" 
                  @change="(e)=>changeNumber(e, itemorder)"></a-input-number>
                <a-input-number v-if="itemorder.order_type == 3" :min="0" :step="0.01" 
                  v-model="itemorder.formData.audit_express_order_refund_amount" 
                  @change="(e)=>changeNumber(e, itemorder)"></a-input-number>
                元
                <span v-if="itemorder.containsFreight && itemorder.order_type!=1">（含运费）</span>
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 审核内容 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item prop :labelCol="{ span: 2 }" label="备注说明">
                <a-input 
                  placeholder="内部备注、顾客不可见"
                  v-model="itemorder.formData.audit_remarks"
                />
              </a-form-model-item>
            </a-col>
          </a-row>

        </a-form-model>
      </div>

      <!-- 底部按钮 -->
      <div>
        <div class="flex items-center justify-center pt-3" style="border-top:1px solid lightgray;">
          <a-button @click="handlerCancel" type="info" class="mr-7">取消</a-button>
          <a-button @click="handlerSave" type="primary">提交（仍需第三方平台继续操作）</a-button>
        </div>
      </div>
    </a-modal>

    <a-modal
      class="preview-class"
      title="退款图片"
      width="50%"
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false"
    >
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import {
  otherOrderApplyRefund,
} from "@/api/customer"
import { formatGoodsType } from '@/utils/type.js'
import uploadImage from "@/components/upload-image"

// const ORDER_TYPE = {
//   1: "自提",
//   2: "配送",
//   3: "快递",
// }

const REASON_LIST = [
  '顾客取消订单', 
  '商品错发、漏发', 
  '商品质量问题', 
  '快递一直未送达', 
  '未按约定时间发货', 
  '包装、商品破损'
]

export default {
  components: { uploadImage },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
    },
    otherOrderId: {
      type: [String, Number],
    },
    data: {
      type: Array,
      default: () =>[],
    },
  },
  data () {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },

      REASON_LIST,
      previewVisible: false,
      previewImage: "",
      invoiceColumns: [
        {
          title: "分类",
          dataIndex: "goods_type",
          align: "center",
          width: "80px",
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
        {
          title: "SKU",
          dataIndex: "goods_sku_number",
          align: "center",
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
        },
        {
          title: "订单价格",
          dataIndex: "order_price",
          align: "center",
          width: "80px",
        },
      ],



      infoDataArr: []

    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    formatGoodsType,
    onChangeAll(e, item) {
      // console.log('onchangeall', e.target.checked, item)
      item.goods_list.map(el=>{
        el.checked = e.target.checked
      })
      this.changeGoods(item)
    },

    onChangeItem(e, item){
      // console.log('onChangeItem', e.target.checked, item)
      this.changeGoods(item)
    },

    handleChangeSelectReason() {
      this.$forceUpdate()
    },

    async initData () {
      if (this.data) {
        let data = JSON.parse(JSON.stringify(this.data))
         
        data.map((el) => {
          el.formData = {
            refund_reason: undefined,
            attached_images_path: "",

            audit_pick_up_order_refund_amount: 0,
            audit_delivery_order_refund_amount: 0,
            audit_express_order_refund_amount: 0,

            audit_remarks: "",

            // 以下不需要提交
            apply_pick_up_order_refund_amount: 0,
            apply_delivery_order_refund_amount: 0,
            apply_express_order_refund_amount: 0,
          }
          el.imgList = []
          el.goods_list.map(e=>{
            e.checked = false
          })
          el.containsFreight = false
        })
        this.infoDataArr =data
        console.log('init', this.infoDataArr)
      }
    },

    changeGoods(order){
      console.log('changeGoods', order)

      let isAllSelect = true

      order.goods_list.map(()=>{
        let goodslist = order.goods_list.filter(good=>{
          return good.checked
        })

        if(goodslist.length != order.goods_list.length){
          isAllSelect = false
        }
        // 子订单的退款商品金额
        let amount = 0
        goodslist.map(el=>{
          amount += Number(el.order_price)
        })
        if(order.order_type == 1){
          order.formData.apply_pick_up_order_refund_amount = amount
        }else if(order.order_type == 2){
          order.formData.apply_delivery_order_refund_amount = amount
        }else if(order.order_type == 3){
          order.formData.apply_express_order_refund_amount = amount
        }
      })

      // 运费
      const freight_amount = Number(order.freight_amount)
      // 退全部商品的话 加运费
      order.containsFreight = isAllSelect && freight_amount>0

      // console.log('aaa', order.containsFreight, freight_amount)
      if(order.containsFreight){
        if(order.formData.apply_delivery_order_refund_amount > 0){
          order.formData.apply_delivery_order_refund_amount += freight_amount
        }else if(order.formData.apply_express_order_refund_amount > 0){
          order.formData.apply_express_order_refund_amount += freight_amount
        }
      }


      // 重算子订单退款金额
      order.formData.audit_pick_up_order_refund_amount = order.formData.apply_pick_up_order_refund_amount.toFixed(2)
      order.formData.audit_delivery_order_refund_amount = order.formData.apply_delivery_order_refund_amount.toFixed(2)
      order.formData.audit_express_order_refund_amount = order.formData.apply_express_order_refund_amount.toFixed(2)

      // order.formData.order_goods_ids = selectedIds.join(",")
    },
    changeNumber(val, item){

      if(item.formData.apply_pick_up_order_refund_amount == 0){
        item.formData.audit_pick_up_order_refund_amount = 0
      }
      if(item.formData.apply_delivery_order_refund_amount == 0){
        item.formData.audit_delivery_order_refund_amount = 0
      }
      if(item.formData.apply_express_order_refund_amount == 0){
        item.formData.audit_express_order_refund_amount = 0
      }
      // 校验金额：退款不能超额
      const audit_amount = Number(item.formData.audit_pick_up_order_refund_amount) 
                        + Number(item.formData.audit_delivery_order_refund_amount) 
                        + Number(item.formData.audit_express_order_refund_amount)
      if(audit_amount > Number(item.order_amount+item.freight_amount)){
        this.$message.info('退款金额不能大于订单总额')
      }
    },
    handlerSave () {
      console.log('handlerSave', this.infoDataArr)
      let hasSelect = false
      this.infoDataArr.map(el => {
        el.goods_list.map(e => {
          if(e.checked) {
            hasSelect = true
          }
        })
      })
      if(!hasSelect){
        this.$message.info('请勾选退款商品')
        return false
      }
      let postArr = []
      this.infoDataArr.map(el => {
        let elItemHasSelect = false
        let ids = []
        el.goods_list.map(e => {
          if(e.checked) {
            elItemHasSelect = true
            ids.push(e.order_goods_id)
          }
        })
        if(elItemHasSelect) {//当前订单有勾选商品
          const audit_amount = Number(el.formData.audit_pick_up_order_refund_amount) 
                            + Number(el.formData.audit_delivery_order_refund_amount) 
                            + Number(el.formData.audit_express_order_refund_amount)
          if(audit_amount > Number(el.order_amount + el.freight_amount)){
            this.$message.info('退款金额不能大于订单总额')
            return false
          }

          let refundOrder = {
            order_no: el.order_no,
            order_goods_ids: ids.join(','),
            refund_reason: el.formData.refund_reason,
            attached_images_path: el.imgList.join(","),
            audit_pick_up_order_refund_amount: el.formData.audit_pick_up_order_refund_amount,
            audit_delivery_order_refund_amount: el.formData.audit_delivery_order_refund_amount,
            audit_remarks: el.formData.audit_remarks
          }

          postArr.push(refundOrder)
        }
      })
      
      // console.log('postarr', postArr)
      let params = {
        other_order_id: this.otherOrderId,
        apply_refund_order_data: JSON.stringify(postArr)
      }
      // console.log('params', params)
      // this.formData.attached_images_path = this.imgList.join(",")
      otherOrderApplyRefund(params).then(res => {
        if (res.code === 0) {
          this.$message.success('已提交')
          this.$parent.initData()
          this.$emit('update:show', false)
        }
      })
    },

    handlerCancel () {
      this.$emit('update:show', false)
    },

    handlerBigImg (e) {
      this.previewImage = e.image_url
      this.previewVisible = true
    },

  },
}
</script>

<style lang="less" scoped>
.audit_amount_input{
  // max-width:140px !important;
  .ant-input-number{
    width: 100px;
    max-width:120px;
  }
  .plus{
    margin: 0 5px;
  }
}
.footer {
  margin: 20px 0;
  .img-class {
    width: 100px;
    height: 75px;
    // object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
.thirdparty-refund /deep/ .ant-modal-body {
  padding-top: 0;
}
.thirdparty-refund /deep/ .ant-empty-normal {
  margin: 0;
}
.tablegoodlist {
  width: 100%;
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  text-align: center;
  tr {
    line-height: 30px;
  }
  th, td {
    border: 1px solid #e8e8e8;
  }
  th {
    background: #fafafa;
    font-size: 12px;
  }
  td {
    font-size: 12px;
  }
}
</style>