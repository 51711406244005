
import { getGoodsListByOutletsId } from "@/api/order/create"

// sku码、网点id
export async function filterSkuList(sku_nums, outlets_id) {
  sku_nums = sku_nums || []
  const { data, code } = await getGoodsListByOutletsId({
    outlets_id: outlets_id,
    page: 1,
    page_count: 100,
  })
  if (code === 0) {
    let skuArr = []
    const datas = data.list
    datas.map((goods) => {
      goods.sku_data.map((item) => {
        let obj = goods
        obj.flavor_name = item.flavor_name
        if (item.specification_list.length > 0) {
          item.specification_list.map((el) => {
            obj = { ...obj, ...el }
            delete obj.sku_data
            skuArr.push(obj)
          })
        }
      })
    })
    return skuArr.filter(el=>{
      return sku_nums.includes(el.goods_sku_number)
    })
  }
  return []
}












