<template>
  <a-modal :visible="show"
           title="流水记录"
           destroyOnClose
           width="900px"
           @cancel="$emit('update:show', false)"
           :footer="null">
    <a-tabs type="card" class="w-full" @change="handlerTypeChangeModal" :activeKey="activeKeyModal">
      <a-tab-pane key="1" tab="退费流水">
      </a-tab-pane>
      <a-tab-pane key="2" tab="补差流水">
      </a-tab-pane>
    </a-tabs>
    <base-table class="mb-4" 
      rowKey="flow_no"
      :columnsData="flowLogColumn" 
      :tableData="flowLogTableData" 
      :customHeight="300"
    >
    </base-table>
  </a-modal>
</template>

<script>
import {
  // getCakeOrderList, //test
  otherOrderGetFlowLog,
} from "@/api/customer"
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: [Number,String],
      default: ''
    }
  },
  data () {
    return {
      activeKeyModal: "1",
      flowLogColumn: [
        {
          title: "上传时间",
          dataIndex: "add_time",
          align: "center",
          // width: 130,
        },
        {
          title: "操作人",
          dataIndex: "operator",
          align: "center",
          width: 100,
        },
        {
          title: "流水金额",
          dataIndex: "amount",
          align: "center",
          width: 100,
        },
        {
          title: "流水时间",
          dataIndex: "flow_time",
          align: "center",
          // width: 130,
        },
        {
          title: "备注",
          dataIndex: "remarks",
          align: "center",
          width: 180,
        },
      ],
      flowLogTableData: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      let { data, code } = await otherOrderGetFlowLog({ other_order_id: this.orderId, flow_type: this.activeKeyModal })

      if(code == 0){
        this.flowLogTableData = data.list
      }
    },
    handlerTypeChangeModal(e) {
      this.activeKeyModal = e
      this.initData()
    }
  }
}
</script>

<style>

</style>